@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
}
body {
   margin: 0;
   padding: 0;
   font-family: 'Open Sans', sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

